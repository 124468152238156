import { useLocation } from "react-router-dom";
import styled from "styled-components";

const PrintableWrapper = styled.div`
  --legal-paper-width: 21cm;
  --legal-paper-height: 36cm;
  --paper-margin-top: 20mm;
  --paper-margin-right: 15mm;
  --paper-margin-bottom: 20mm;
  --paper-margin-left: 20mm;
  --paper-content-width: calc(
    var(--legal-paper-width) - var(--paper-margin-left) - var(--paper-margin-right)
  );

  @media print {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }

  * {
    box-sizing: border-box;
    font-size: 16px;
  }
  [contenteditable="true"] {
    background-color: lightgoldenrodyellow;
  }
  .text-right {
    text-align: right;
  }

  body {
    width: var(--legal-paper-width);
    height: var(---legal-paper-height);
    margin: 20mm 15mm 20mm 15mm;
    margin-left: auto;
    margin-right: auto;
  }

  /* ONLY FOR TESTING
  border: 1px dashed red;
  background-color: #eee; */

  .header,
  .section {
    width: var(--paper-content-width);
    margin-left: auto;
    margin-right: auto;
  }

  .header {
    display: block;
    height: 300px;

    h2 {
      text-align: center;
    }

    .logo {
      display: inline-block;
      font-size: 80px;
    }
    .stampBox {
      width: 120px;
      float: right;
      .box {
        width: 110px;
        height: 150px;
        border: 2px solid;
      }
    }
  }

  .section {
    margin-top: 1cm;
    width: var(--paper-content-width);

    .info {
      text-align: center;
      font-size: 10px;
    }

    .boxes {
      width: var(--paper-content-width);
      display: grid;
      grid-template-columns: repeat(9, auto);

      .box-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        border: 1px solid black;

        .box-item-value {
          display: flex;
          flex-grow: 1;
          align-items: center;
          font-weight: 600;
        }
      }
    }
  }
  .row {
    margin-top: 0.1cm;
  }
`;

export default function PrintCedula({data}) {
  const location = useLocation();
  data = location.state?.data;
  data = data || {
    nroBoleta: "1234/12/12",
    nroExpediente: "E001234/12/12",
    demandado: "AUTOMOVILES Y SERVICIOS S.R.L.",
    domicilio: "SUIPACHA 211 PISO 11 OF. C",
    localidad: "C.A.B.A.",
    fuero: "SS",
    juzgado: "4",
    secretaria: "2",
  };

  const now = new Date();
  const month = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
  const today = {
    date: now.getDate(),
    month: month[now.getMonth()],
    year: now.getFullYear()
  }

  return (
    <PrintableWrapper>
      <div className="header">
        <h2>PODER JUDICIAL DE LA NACION</h2>
        <div className="logo">{data.fuero}</div>
        <div className="stampBox">
          <div>CEDULA DE NOTIFICACION</div>
          <div className="box"> </div>
        </div>
      </div>

      <div className="section">
        <div className="row">
          BD: <strong>{data.nroBoleta}</strong>
        </div>
        <div className="row">FECHA DE RECEPCIONES EN NOTIFICACIONES</div>
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          <span>SE&Ntilde;OR:</span> <strong>{data.demandado}</strong>
        </div>
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          <span>DOMICILIO:</span>{" "}
          <strong>
            {data.domicilio} - {data.localidad}
          </strong>
        </div>
      </div>

      <div className="section">
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          ------------------------------------ CONSTITUIDO ------------------------------------
        </div>
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          <span>CARACTER: </span>
          ---------------------------------------------------------------------------
        </div>
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          <span>OBSERVACIONES ESPECIALES: </span>
          <span className="info">(Urgente, notificar en el día, habilitacion de dia y hora inhabil)</span>
        </div>
      </div>

      <div className="section">
        <div className="boxes">
          <div className="box-item">
            <div> </div>
            <div>N°ORDEN</div>
          </div>

          <div className="box-item">
            <div className="box-item-value">{data.nroExpediente}</div>
            <div>EXP.N°</div>
          </div>

          <div className="box-item">
            <div className="box-item-value" contenteditable="true">&nbsp;&nbsp;&nbsp;</div>
            <div>ZONA</div>
          </div>

          <span className="box-item">
            <div className="box-item-value">{data.fuero}</div>
            <div>FUERO</div>
          </span>

          <span className="box-item">
            <div className="box-item-value">{data.juzgado}</div>
            <div>JUZGADO</div>
          </span>

          <span className="box-item">
            <div className="box-item-value">{data.secretaria}</div>
            <div>SECRET.</div>
          </span>

          <span className="box-item">
            <div className="box-item-value" contentEditable="true" suppressContentEditableWarning={true}>
              SI / NO
            </div>
            <div>COPIAS</div>
          </span>

          <span className="box-item">
            <div className="box-item-value"> </div>
            <div>PERSONAL</div>
          </span>

          <span className="box-item">
            <div className="box-item-value"> </div>
            <div>OBSERVAC.</div>
          </span>
        </div>
      </div>

      <div className="section">
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          Hago saber a Usted que en el Expediente caratulado "FISCO NACIONAL (AFIP-DGI) c/&nbsp;
          <strong>{data.demandado}</strong> s/ EJECUCION FISCAL",&nbsp; se ha dictado la siguiente{" "}
          <strong>Resolución</strong>:
        </div>
        <div className="row" contentEditable="true" suppressContentEditableWarning={true}>
          La presente notificación se practica de conformidad con lo establecido en los artículos 92 y 100
          inc. c) de la ley 11683 (t.o.1998 y sus modificaciones)
        </div>
      </div>

      <div className="section text-right">
        <div className="row">
          <span className="">QUEDA USTED NOTIFICADO.</span>
        </div>

        <div className="row">
          Buenos Aires,{" "}
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {today.date}
          </span>{" "}
          de{" "}
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {today.month}
          </span>{" "}
          de{" "}
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {" "}
            {today.year}.
          </span>
        </div>
      </div>

      <div className="section text-right">
        <div className="row">Dr. Carlos Jorge Atucha</div>
        <div className="row">T&deg;36 - F&deg;785</div>
        <div className="row">COLEGIO DE ABOGADOS</div>
        <div className="row">CAPITAL FEDERAL</div>
      </div>

      <div className="section">
        <div>
          Dependencia AFIP:Agencia N°49 - Tte.Gral. Perón 1378 - piso 1° - Gestión Judicial - Teléfono:
          4829-2509 / Celular: 15-5-839-0101
        </div>
      </div>
    </PrintableWrapper>
  );
}
