import { format } from "date-fns";
import { Timestamp } from "firebase/firestore";

export function handleInputChange(event, inputValues, setInputValues) {
  const name = event.target.name;
  const value = event.target.type !== "checkbox"
    ? event.target.value
    : event.target.checked;

  setInputValues({ ...inputValues, [name]: value });
};

export function deepEquals(arg1, arg2) {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
    if (
      Object.prototype.toString.call(arg1) === "[object Object]" ||
      Object.prototype.toString.call(arg1) === "[object Array]"
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every(function (key) {
        return deepEquals(arg1[key], arg2[key]);
      });
    }
    return arg1 === arg2;
  }
  return false;
}

export function getDateString(date, dateFormat) {
  dateFormat = dateFormat || "dd/MM/yyyy"; //"yyyy-MM-dd";
  let result = '';
  if (date instanceof Date) {
    result = format(date, dateFormat);
  } else if (date instanceof Timestamp) {
    result = format(date.toDate(), dateFormat);
  } else if (date instanceof Object) {
    const ts = new Timestamp(date.seconds, date.nanoseconds);
    result = format(ts.toDate(), dateFormat);
  }
  return result;
}

// @deprecated
// export function convertNovedadesTimestampToDate(novedades, dateFormat) {
//   const updatedNovedades = novedades.map((n) => {
//     if (n.fecha instanceof Timestamp) {
//       n.fecha = getDateString(n.fecha);
//     }
//     return n;
//   });
//   return updatedNovedades;
// };

