import {useState} from 'react'
import * as FirestoreService from "../services/firestore";
import { getDateString, handleInputChange } from '../utils/utils';

import {subDays} from "date-fns";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import styled from "styled-components";
import { useTitle } from '../hooks/useTitle';
const FormStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .fueros-selection {
    input[type="radio"] {
      opacity: 0;
      position: fixed;
      width: 0;
    }
    label {
      display: inline-block;
      background-color: #ddd;
      padding: 5px 5px;
      border: 2px solid #444;
      border-radius: 4px;
    }
    input[type="radio"]:checked {
      border: 1px solid red;
    }
    input[type="radio"]:checked + label {
      background-color: #bbbeff; /*#bfb;*/
      border-color: #444ccc; /*#4c4;*/
    }
    label:hover {
      background-color: #dde0ff; /*#dfd;*/
    }
  }

  .btn {
    background-color: #216ba5;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 5px 15px;
  }

  /* .defined-range-wrapper {
    width: fit-content;
    border: 1px solid black;
    * {
      font-size: 0.75rem !important;
    }
  } */
`;

const Styles = styled.div`
  @media print {
    /* position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center; */

    .search-item {
      page-break-inside: avoid;
      padding-bottom: 2cm !important;
    }
  }

  .header {
    color: #216ba5;
    font-size: 1.2rem;
    grid-column: span 3;
  }

  .header,
  .main {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 10px;

    .search-info {
      margin: 5px;
    }
    .search-list {
      display: flex;
      flex-direction: column;

      .search-item {
        margin: 5px;
        display: grid;
        grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(120px, 1fr) minmax(110px, 1fr);
        grid-template-rows: auto;

        .span-row {
          grid-column: 1 / -1;
          background-color: ghostwhite;
        }
        .span75p {
          grid-column: 1 / -2;
          /* background-color: ghostwhite; */
        }
        .center {
          justify-self: center;
          align-self: center;
        }
      }
    }

    .label {
      font-weight: 600;
    }
  }

  .card {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 10px;
  }

  .money {
    &:before {
      content: "$ ";
    }
    font-weight: 600;
  }
`;

export default function ReportsPage() {
  useTitle("Agente Judicial | Reportes");

  const [juicios, setJuicios] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState();
  const [inputValues, setInputValues] = useState({
    selectedFuero: "Todos",
    startDate: subDays(new Date(), 30),
    endDate: new Date()
  });


  const fueros = ['Todos', 'CA', 'SS', 'CAEF'];

  const handleBuscar = async () => {
    const criteria = [
      { field: "fechaDemanda", operator: ">=", value: new Date(inputValues.startDate) },
      { field: "fechaDemanda", operator: "<=", value: new Date(inputValues.endDate) },
    ];
    if (inputValues.selectedFuero !== "Todos") {
      criteria.push({ field: "fuero", operator: "==", value: inputValues.selectedFuero });
    }
    console.log("criteria", criteria)
    setSearchCriteria({
      from: inputValues.startDate,
      to: inputValues.endDate,
      fueros: inputValues.selectedFuero
    });

    const querySnap = await FirestoreService.getJuiciosBy(criteria);
    const results = FirestoreService.getDataFromQuerySnapshot(querySnap);
    console.log("Results:", results);
    setJuicios(results);
  }

  return (
    <Styles>
      <div className="page-name">[Reports Page]</div>

      <div className="header">Reporte de Juicios</div>

      <div className="main">
        <div className="span-row search">
          <div className="section form">
            <FormStyled>
              <div className="fueros-selection">
                <div>Includir Fueros:</div>
                {fueros.map((fuero, idx) => (
                  <span key={`${fuero}_${idx}`}>
                    <input
                      type="radio"
                      name="selectedFuero"
                      id={`Fuero_${fuero}`}
                      checked={inputValues.selectedFuero === fuero}
                      value={fuero}
                      onChange={(e) => handleInputChange(e, inputValues, setInputValues)}
                    />
                    <label htmlFor={`Fuero_${fuero}`}>{fuero}</label>
                  </span>
                ))}
              </div>

              <div>
                <div>Fecha Demanda:</div>
                <label htmlFor="startDate">Desde: </label>
                <input
                  type="date"
                  name="startDate"
                  onChange={(e) => handleInputChange(e, inputValues, setInputValues)}
                  value={inputValues.startDate}
                  min="2000-01-01"
                  max={new Date().toISOString().split("T")[0]}
                />
                <label htmlFor="endDate">Hasta: </label>
                <input
                  type="date"
                  name="endDate"
                  onChange={(e) => handleInputChange(e, inputValues, setInputValues)}
                  value={inputValues.endDate}
                  min={inputValues.startDate}
                  // max={new Date().toISOString().split("T")[0]}
                />
              </div>

              <div>
                <button className="btn" onClick={handleBuscar}>
                  Buscar
                </button>
              </div>
            </FormStyled>
          </div>
        </div>

        {juicios.length > 0 && (
          <>
            <div className="search-info">
              <div>
                Buscando fuero <strong>{searchCriteria.fueros} </strong>
                desde la fecha <strong>{searchCriteria.from?.toLocaleString()} </strong>
                hasta la fecha <strong>{searchCriteria.to?.toLocaleString()} </strong>
              </div>
              <div>
                Juicios encontrados: <strong>{juicios.length}</strong>
              </div>
            </div>
            <hr />
            <div className="search-list">
              {juicios.map((juicio) => (
                <div
                  key={juicio.id}
                  className="search-item card"
                  // onClick={() => navigateTo(`/juicios/${juicio.id}`, { juicio: { ...juicio } })}
                >
                  <div>
                    <span className="label">BD N&deg;:</span> {juicio.nroBoleta}
                  </div>
                  <div>
                    <span className="label">Exp N&deg;:</span> {juicio.nroExpediente}
                  </div>
                  <div className="center">
                    <span>
                      {juicio.fuero} / J:{juicio.juzgado} / S:{juicio.secretaria}
                    </span>
                  </div>
                  <div className="center">
                    <span>{getDateString(juicio.fechaDemanda)}</span>
                  </div>

                  <div className="span-row">
                    <span>
                      {juicio.demandado} ({juicio.cuit})
                    </span>
                  </div>

                  <div className="span75p">
                    <span>{juicio.reclamo}</span>
                  </div>
                  <div className="center">
                    <span className="money">{juicio.monto?.toLocaleString()}</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Styles>
  );

}
