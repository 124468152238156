import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { logIn, logOut, useAuth } from '../services/firestore';
import { handleInputChange } from '../utils/utils'

import styled from "styled-components";
import { useTitle } from '../hooks/useTitle';

const Styles = styled.div`
  display: flex; /* default row main-axis */
  justify-content: center; /* main axis */
  /* margin-top: 50px; */

  .login-container {
    background-color: ghostwhite;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.57);
    padding: 10px;
    margin: 15px 10px;
    width: 300px;

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    input {
      text-align: center;
    }

    .form-actions {
      display: flex;
      justify-content: space-around;

      button {
        margin: 5px 5px;

        &:disabled {
          display: none;
        }
      }
    }
  }
`;

export default function LoginPage() {
  useTitle("Agente Judicial | Login");
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    email: '',
    password: ''
  });
  const currentUser = useAuth();
    const navigate = useNavigate();

  // const emailRef = useRef();
  // const passwordRef = useRef();



  async function handleLogIn() {
    try {
      setLoading(true);
      //const result = await logIn(emailRef.current.value, passwordRef.current.value);
      // const result = await logIn(inputValues.email, inputValues.password);
      // console.log("Login result", result);
      await logIn(inputValues.email, inputValues.password);
      navigate("/");
    } catch (e) {
      console.log("err", e);
    }
    setLoading(false);
  }

  async function handleLogOut() {
    setLoading(true);
    try {
      logOut();
    } catch (e) {
      console.log("err", e);
    }
    setLoading(false);
  }

  return (
    <Styles>
      <div className="page-name">LoginPage</div>
      <div className="login-container">
        <input
          // ref={emailRef}
          type="email"
          placeholder="Email"
          name="email"
          onChange={(e) => handleInputChange(e, inputValues, setInputValues)}
          value={inputValues.email}
        />
        <input
          // ref={passwordRef}
          type="password"
          placeholder="Password"
          name="password"
          onChange={(e) => handleInputChange(e, inputValues, setInputValues)}
          value={inputValues.password}
        />

        <div className="form-actions">
          <button onClick={handleLogIn} disabled={loading || currentUser}>
            Login
          </button>
          <button onClick={handleLogOut} disabled={loading || !currentUser}>
            Log Out
          </button>
        </div>
      </div>
    </Styles>
  );
}

