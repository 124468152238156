import React from 'react';
import ReactDOM from 'react-dom/client';
// read https://medium.com/@vergil333/google-analytics-4-in-react-a250005c2455
import ReactGA from "react-ga4";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
  ReactGA.initialize(process.env.REACT_APP_FIREBASE_MEASUREMENT_ID);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
function sendToAnalytics({ id, name, value }) {
  ReactGA.event({
    category: "Web Vitals",
    action: name,
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    label: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
    transport: "beacon", // Use `sendBeacon()` if the browser supports it.
  });
}
reportWebVitals(sendToAnalytics);
