import {useEffect, useState} from 'react'
import {
  FaUser as UserIcon,
  FaBuilding as BuildingIcon,
  FaRegIdCard as IdIcon,
  FaGavel as GavelIcon,
  FaEdit as EditIcon,
  FaPrint as PrintIcon,
  FaTrash as TrashIcon
} from "react-icons/fa";
import styled from "styled-components";

import NovedadForm from './NovedadForm';
import {getDateString,deepEquals} from '../utils/utils';
import EtapasTimeline from './EtapasTimeline/EtapasTimeline';
import { Link } from 'react-router-dom';

const LegacyStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 200px;
  flex-wrap: wrap;

  .juicio-info {
    display: flex;
    flex-direction: column;
    min-width: 400px;
  }
  .juicio-info,
  .juicio-updates {
    // - https://mastery.games/post/flex-wrap/
    // - https://mastery.games/post/the-difference-between-width-and-flex-basis/
    flex: 1 1 420px;
  }

  .section {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 15px 10px;
    min-width: 300px;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.25rem;
      .title {
        font-size: 2rem;
        /*
        thin 100 ; normal 400 ; bold 700 ; heavy 900
        */
        font-weight: 500;
      }
      .subtitle {
        font-size: 1.5rem;
        font-weight: 500;
        margin-left: 5px;
      }
      .subtitle2 {
        font-style: italic;
        font-size: 0.8rem;
      }
    }
    main {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      .subject {
        /* grid-area: 2 / 1 / 2 / 2; */
        grid-row: 2 / 2;
        grid-column: 1 / -1;
      }
      .etapa {
        position: absolute;
        top: 21px;
        right: -20px;
        /* row/column start row/column end */
        grid-area: 2 / 2 / 2 / -1;
        z-index: 2;
      }

      .novedad-form {
        grid-column: 1 / -1;
        padding: 5px 10px 5px 50px;

        .comentario {
          display: flex;
          flex-direction: column;

          small {
            margin-left: auto;
          }
        }
      }

      .novedades {
        grid-column: 1 / -1;

        .novedad {
          width: 90%;
          .tipo-novedad {
            margin-right: 10px;
          }
        }
      }

      .span {
        grid-column: span 2;
      }
    }
  }

  .highlight {
    border-left: 5px solid #ee6373;
  }

  .etapas-timeline {
    width: 90%;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;

    .print-buttons {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;

    text-decoration: none;
    cursor: pointer;

    .button-icon {
      color: white;
    }
    &.button-edit {
      background: cornflowerblue;
    }
    &.button-print {
      background: coral;
    }
    &:hover {
      transition: filter 250ms;
      filter: drop-shadow(0 0 5px grey);
    }
    & > span {
      font-size: .8rem;
      color: white;
      padding-top: 5px;
    }
  }

  .paper-sheet {
    position: relative;
    border: 1px solid rgba(222, 2222, 222, 0.5);
    /* background: -webkit-linear-gradient(top, #dfe8ec 0%, white 8%) 0 57px; */
    background: -webkit-linear-gradient(top, #dfe8ec 0%, #fefefa 8%) 0 57px;
    background-size: 100% 20px;

    &::after {
      content: "";
      position: absolute;
      width: 0px;
      top: 0;
      left: 35px;
      bottom: 0;
      border-left: 1px solid #f55;
    }
  }

  .chip {
    padding: 7px 10px;
    background-color: #e4e4e4;
    border-radius: 16px;
    text-align: center;

    &.chip-E {
      padding: 0 10px;
      color: white;
      background-color: #195;
    }
    &.chip-G {
      padding: 0 10px;
      color: white;
      background-color: #fa4;
    }
    &.chip-C {
      padding: 0 10px;
      color: white;
      background-color: #338;
    }
  }

  .stamp {
    transform: rotate(-5deg);
    color: #555;
    border: 0.25rem solid #d67;
    display: inline-block;
    padding: 0.25rem 0.5rem;
    mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png");
  }

  .clickable {
    width: fit-content;

    &:hover {
      color: darkorange; /*burlywood;*/
      border-bottom: 1px dashed burlywood;
      cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAAnklEQVQ4je3SsQ3CMBQE0JeEMivQsAUDUFOBKJHYgDodE9CzARUtC8AACBZgAAQtEjRGRMiyEmg5yTr9b9/5vmx+RBa4hwplA824XuSBKwy+SdAJXGL36d4EeaS3wh2PxLphWE9QxxRL7BMXzzHBJmZQBPE6YTAK56IjtMLf4P2MrT/QC1mkd8UB54Sujy1mRWTziK70eCcscGmWM4Eng8wZeKQxzBkAAAAASUVORK5CYII="),
        copy;
    }
  }
`;




export default function JucioLegacy({ data, updateNovedades }) {
  const [loading, setLoading] = useState(true);
  const [novedades, setNovedades] = useState(null);
  const [ultimaEtapa, setUltimaEtapa] = useState("s/ etapas");

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.etapas?.length > 0) {
      // desc b-a ; asc a-b
      const sortedEtapas = data.etapas.sort((a, b) => a.fecha - b.fecha);
      setUltimaEtapa(sortedEtapas[sortedEtapas.length-1].tipo);
    }
    else {
      setUltimaEtapa("s/ etapas");
    }

    const n = composeNovedades();
    setNovedades(n);
    setLoading(false)

    function composeNovedades() {
      const etapas2 = data.etapas ? data.etapas.map((x) => ({ ...x, tipoNovedad: "E" })) : [];
      const comentarios2 = data.comentarios ? data.comentarios.map((x) => ({ ...x, tipoNovedad: "C" })) : [];
      const gestiones2 = data.gestiones ? data.gestiones.map((x) => ({ ...x, tipoNovedad: "G" })) : [];
      const n = [...etapas2, ...gestiones2, ...comentarios2];
      n.sort((a, b) => b.fecha - a.fecha); // sort oldest first / DESC
      return n;
    }
  }, [data, data?.etapas]);

  const saveNovedad = (novedad) => {
    const newEtapas = data.etapas ? [...data.etapas] : [];
    const newGestiones = data.gestiones ? [...data.gestiones] : [];
    const newComentarios = data.comentarios ? [...data.comentarios] : [];

    if (novedad.tipoNovedad === "E") {
      newEtapas.push(novedad)
    } else if (novedad.tipoNovedad === "G") {
      newGestiones.push(novedad);
    } else if (novedad.tipoNovedad === "C") {
      newComentarios.push(novedad);
    }
    updateNovedades(newEtapas, newGestiones, newComentarios)
    // console.log("JUICIO ::: novedad, juicio", novedad, data);
  };
  const deleteNovedad = (novedad) => {
    // console.log("deleting novedad out of novedades e,g,c",
    //   novedad, data.etapas, data.gestiones, data.comentarios);
    if (novedad.tipoNovedad === "E") {
      data.etapas = data.etapas.filter((e) => !deepEquals(e, novedad));
    } else if (novedad.tipoNovedad === "G") {
      data.gestiones = data.gestiones.filter((e) => !deepEquals(e, novedad));
    } else if (novedad.tipoNovedad === "C") {
      data.comentarios = data.comentarios.filter((e) => !deepEquals(e, novedad));
    }
    // console.log("delete result e,g,c",
    //   data.etapas, data.gestiones, data.comentarios);
    updateNovedades(data.etapas, data.gestiones, data.comentarios);
  };

  const copyToClipboard = (element, text) => {
    if (typeof text === 'string' || text instanceof String) {
      navigator.clipboard.writeText(text);
    } else {
      navigator.clipboard.writeText(element.target.textContent);
    }
  }

  if (loading) {
    return <div style={{textAlign:"center"}}>Cargando</div>
  }

  return (
    <LegacyStyles>
      <div className="juicio-info">
        <div className="section highlight">
          <header>
            <span className="title">Juicio</span>
            <span className="chip fjs">
              {data.fuero} &#x2022; {data.juzgado} &#x2022; {data.secretaria}
            </span>
          </header>
          <main>
            <div className="clickable" onClick={() => copyToClipboard(this, data.nroBoleta)}>
              Boleta # {data.nroBoleta}
            </div>
            <div>Expediente # {data.nroExpediente}</div>

            <div className="subject">
              <div>
                <UserIcon /> {data.demandado}
              </div>
              <div>
                <small>
                  <BuildingIcon /> {data.domicilio} {data.localidad}
                </small>
              </div>
              <div>
                <small
                  className="clickable"
                  onClick={() => copyToClipboard(this, data.cuit.replace(/-/g, ""))}
                >
                  <IdIcon /> {data.cuit}
                </small>
              </div>
            </div>

            <div className="etapa stamp">{ultimaEtapa}</div>
          </main>
        </div>

        <div className="section etapas-timeline">
          {data.etapas?.length > 0 && <EtapasTimeline etapas={data.etapas} />}
        </div>

        <div className="section">
          {/* <header>
            <span className="subtitle">Detalles</span>
          </header> */}
          <main>
            <div className="span">
              <GavelIcon /> {data.reclamo}
            </div>

            <div className="chip">Monto $ {data.monto?.toLocaleString("es-AR")}</div>
            <div className="chip">Presup. $ {data.presupuestado?.toLocaleString("es-AR")}</div>

            <div className="chip">F.Adj. {getDateString(data.fechaAdjudicacion)}</div>
            <div className="chip">F.Dem. {getDateString(data.fechaDemanda)}</div>
          </main>
        </div>

        <div className="section action-buttons">
          <span>
            <Link to={`/juicios/${data.id}/edit`} className="button button-edit">
              <EditIcon className="button-icon" />
              <span>Editar</span>
            </Link>
          </span>

          <span className="print-buttons">
            <span>
              <Link to="/printCedula" state={{ data }} className="button button-print">
                <PrintIcon className="button-icon" />
                <span>Cedula</span>
              </Link>
            </span>

            <span>
              <Link to="/printCedulaModel2" state={{ data }} className="button button-print">
                <PrintIcon className="button-icon" />
                <span>Cedula 2</span>
              </Link>
            </span>

            <span>
              <Link to="/printOficio" state={{ data }} className="button button-print">
                <PrintIcon className="button-icon" />
                <span>Oficio</span>
              </Link>
            </span>
          </span>
        </div>
      </div>

      <div className="juicio-updates">
        <div className="section">
          <header className="highlight">
            <span className="subtitle">Novedades</span>
            <div className="subtitle2">Incluye todas las etapas/gestiones/comentarios</div>
          </header>
          <main>
            <div className="novedad-form paper-sheet">
              <NovedadForm saveNovedad={saveNovedad} />
            </div>

            <div className="novedades paper-sheet">
              {!novedades && <p>Cargando novedades</p>}
              {novedades?.map((x, i) => {
                return (
                  <div key={`novedad${i}`} className="novedad">
                    <span className={`tipo-novedad chip chip-${x.tipoNovedad}`}>{x.tipoNovedad}</span>
                    <span>{getDateString(x.fecha)}</span>
                    <span>
                      {x.tipoNovedad === "E" ? `[${x.tipo}]` : ""} {x.comentario}
                      {/* ({x.autor}) */}
                    </span>
                    <span>
                      <TrashIcon size={12} color="darkorange" onClick={() => deleteNovedad(x)} />
                    </span>
                  </div>
                );
              })}
            </div>
          </main>
        </div>
      </div>
    </LegacyStyles>
  );
}
