import { useTitle } from "../hooks/useTitle";



export default function Home() {
  useTitle("Agente Judicial | Inicio");
  return (
    <>
      <div className="page-name">[Home Page]</div>
    </>
  );
}
