import { useEffect, useState } from 'react';

import { initializeApp } from 'firebase/app'
import { getFirestore, Timestamp,
  doc, getDoc, getDocs, collection, query, where, addDoc, setDoc, orderBy
} from "firebase/firestore";
import {
  getAuth, onAuthStateChanged,
  signInAnonymously, signInWithEmailAndPassword, signOut
} from "firebase/auth";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});
const db = getFirestore(app);
const auth = getAuth(app);

// const juiciosCollectionName = "test_sqlid_lower";
const juiciosCollectionName = "estudioatucha_juicios";
export const collectionRef = collection(db, juiciosCollectionName);

export const authenticateAnonymously = () => {
  return signInAnonymously(auth);
};

export const logIn = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
}
export const logOut = () => {
  signOut(auth);
}

/* *** CREATE / UPDATE *********** */

export const addJuicio = (juicio) => {
  juicio = { ...juicio, _created: Timestamp.now() };
  return addDoc(collectionRef, juicio);
}
export const setJuicio = async (id, juicio) => {
  const docRef = doc(db, juiciosCollectionName, id);
  juicio = { id, ...juicio, _updated: Timestamp.now() };
  await setDoc(docRef, juicio);
  return docRef;
}

/* *** READ *********** */

export const getJuicioByKey = (key) => {
  console.log("query by key");
  const juicioDocRef = doc(db, juiciosCollectionName, key)
  return getDoc(juicioDocRef);
};

export const getJuicioByNroExpediente = (nroExpediente) => {
  console.log("query by nroExpediente");
  const q = query(collection(db, juiciosCollectionName), where("nroExpediente", "==", nroExpediente));
  return getDocs(q);
};

export const getJuicioByNroBoleta = (nroBoleta) => {
  console.log("query by nroBoleta");
  const q = query(collection(db, juiciosCollectionName), where("nroBoleta", "==", nroBoleta));
  return getDocs(q);
};

export const getJuiciosByCuit = (cuit) => {
  console.log("query by cuit");
  const q = query(collection(db, juiciosCollectionName), where("cuit", "==", cuit), orderBy("fechaDemanda"));
  return getDocs(q);
};

export const getJuiciosByDemandado = (demandado, useSplit = false) => {
  console.log("query by demandado, useSplit", demandado, useSplit);
  const demandadoLower = demandado.toLowerCase();

  // conditions = conditions.length > 0 ? conditions : "useText";
  // console.log("query by demandado, conditions", demandado, conditions);
  // conditions.map(condition => {
  //   if (condition === "use-text") {
  //     const q = query(
  //       collection(db, juiciosCollectionName),
  //       where("demandado_lower", ">=", demandadoLower),
  //       where("demandado_lower", "<=", demandadoLower + "\uf8ff")
  //     );
  //     return q;
  //   } else if (condition === "use-split") {
  //     const demandadoSplit = demandadoLower.split(" ").map((word) => word.trim());
  //     const q = query(
  //       collection(db, juiciosCollectionName),
  //       where("demandado_split", "array-contains-any", demandadoSplit)
  //     );
  //     return q;
  //   }
  //   return [];
  // })

  let q;
  if (useSplit) {
    const demandadoSplit = demandadoLower.split(" ").map((word) => word.trim());
    q = query(
      collection(db, juiciosCollectionName),
      where("demandado_split", "array-contains-any", demandadoSplit),
      orderBy("demandado_split"),
      orderBy("fechaDemanda")
    );
  } else {
    q = query(
      collection(db, juiciosCollectionName),
      where("demandado_lower", ">=", demandadoLower),
      where("demandado_lower", "<=", demandadoLower + "\uf8ff"),
      orderBy("demandado_lower"),
      orderBy("fechaDemanda")
    );
  }

  return getDocs(q);
}

/*
 criteria = [
  {field, operator, value}, ...
 ]
*/
export const getJuiciosBy = (criteria) => {
  console.log("query juicios");
  const collectionArg = collection(db, juiciosCollectionName);
  const whereArgs = [];
  criteria.forEach(({field, operator, value}) => {
    whereArgs.push(where(field, operator, value));
  });
  const args = [collectionArg, ...whereArgs, orderBy("fechaDemanda")];
  let q = query.apply(this, args || []);

  return getDocs(q);
};

export const getDataFromQuerySnapshot = (qs) => {
  let results = [];
  qs.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() });
  });
  return results;
}


export const getAll = async () => {
  console.log("query all");
  const q = query(collectionRef, where('sql_id', '!=', -1))
  const snapshot = await getDocs(q);
  const results = getDataFromQuerySnapshot(snapshot);
  return results;
}


// Custom Hook
export function useAuth() {
  const [ currentUser, setCurrentUser ] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setCurrentUser(user)
      } else {
        setCurrentUser(null);
      }
    })
    return () => unsubscribe();
  }, [currentUser]);

  return currentUser;
}
