import * as FirestoreService from "../services/firestore";

export default function ExportPage() {

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };
  const exportToJson = async (e) => {
    e.preventDefault();

    let data = "test";
    data = await FirestoreService.getAll();

    downloadFile({
      data: JSON.stringify(data),
      fileName: "users.json",
      fileType: "text/json",
    });
  };

  return (
    <>
      <button type="button" onClick={exportToJson}>
        Export to JSON
      </button>
      <div className="page-name">[Export Page]</div>
    </>
  );
}
