import {useEffect, useRef, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Juicio from '../components/JuicioLegacy';
import { useTitle } from '../hooks/useTitle';

import * as FirestoreService from "../services/firestore";
import { deepEquals } from '../utils/utils';

export default function JuicioPage({searchInputRef}) {
  useTitle("Agente Judicial | Ver Juicio");

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [juicio, setJuicio] = useState(null);
  const { idParam } = useParams();

  const isFirstMount = useRef(true);

  useEffect(() => {
    async function getAndSetJuicio() {
      try {
        const docSnap = await FirestoreService.getJuicioByKey(idParam);
        if (docSnap.exists()) {
          const docSnapData = { ...docSnap.data() };

          // FIXME loosing Firestaore Timestamp object due to JSON serialization
          // https://stackoverflow.com/questions/63326419/web-firebase-var-changes-from-timestamp-type-to-object-type-after-return-through
          const juicio = {
            id: docSnap.id,
            ...docSnapData,
          };
          console.log("retrieved juicio from DB");
          // console.log(juicio);
          setJuicio(juicio);
        }
      } catch (e) {
        if (e.code === "permission-denied") {
          setError("[403] Permiso denegado");
        }
      }
    }

    if (isFirstMount.current) {
      isFirstMount.current = false;
      if (!location.state?.juicio) {
        getAndSetJuicio();
      } else {
        if (!deepEquals(location.state.juicio, juicio)) {
          const retrievedJuicio = location.state.juicio;
          setJuicio(retrievedJuicio);
          // delete location.state.juicio;
          console.log("retrieved juicio from state", retrievedJuicio);
        }
      }
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idParam, location.state?.juicio]);

  const updateNovedades = (etapas = [], gestiones = [], comentarios = []) => {
    const updatedJuicio = { ...juicio, etapas, gestiones, comentarios };
    setJuicio(updatedJuicio);
    saveJuicio(updatedJuicio);
    searchInputRef.current.focus();
  };

  const saveJuicio = async (updatedJuicio) => {
    try {
      setLoading(true);
      let docRef = await FirestoreService.setJuicio(updatedJuicio.id, updatedJuicio);
      console.log(`JuicioPage::[Edited] JUICIO with id: ${docRef.id}`, updatedJuicio);
    } catch (e) {
      if (e.code === "permission-denied") {
        alert("Permission Denied :S");
      }
      console.log("JuicioPage::[err]", e);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <div>Cargando...</div>}
      <div className="page-name">Juicio VIEW Page Legacy</div>

      {error && <h2>ERROR: {error}</h2>}
      {juicio &&
        <Juicio data={juicio} updateNovedades={updateNovedades} />
      }

    </>
  );
}
