import {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from "styled-components";

import { FcSearch as SearchIcon } from "react-icons/fc";


import * as FirestoreService from '../services/firestore';
import { useTitle } from '../hooks/useTitle';

const Styles = styled.div`
  .span-row {
    grid-column: span 3;
  }

  .header {
    color: #216ba5;
    font-size: 1.2rem;
    grid-column: span 3;
  }

  .header,
  .main {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 10px;
    margin: 10px;

    input {
      width: 100%;
    }
  }

  .search {
    display: flex;
    align-items: center;
    border: 0.5px solid lightgray;
    border-radius: 25px;
    padding: 3px 7px;
    position:relative;

    .queryType {
      position: absolute;
      top: -20px;
      /* right: 30px; */
      background-color: lightblue;
    }

    input {
      border: none;
      outline: none;
      background: transparent; /* for dark mode */
      background-color: transparent;
      &::placeholder {
        font-size: 0.9rem;
      }
    }
  }

  .search-results {
    margin: 15px 5px;
  }

  .table {
    margin: 5px 2px;
    min-width: 550px;

    .theader,
    .trow {
      display: grid;
      grid-template-columns: 200px minmax(200px, 10fr) 150px;
      grid-gap: 2px;
    }
    .theader {
      background-color: #216ba5;
      color: whitesmoke;
    }
    .trow {
      .nros > * {
        margin: 0 3px;
      }

      &.trow:nth-child(odd) {
        background-color: lightgrey;
      }
      &.trow:nth-child(even) {
        background-color: whitesmoke;
      }
      &:hover {
        cursor: pointer;
        background-color: lightyellow !important;
      }
    }
  }
`;

export default function SearchPage() {
  useTitle("Agente Judicial | Buscar");

  const location = useLocation();
  const navigate = useNavigate();
  const [query, setQuery] = useState(location.state?.query ? location.state?.query : "");
  const [queryType, setQueryType] = useState("");
  const [juicios, setJuicios] = useState([]);
  const [error, setError] = useState();

  // /^(\d{1,5})(\/\d{2,2})?(\/\d{2,2})$/
  const nroExpedientePattern = useMemo(() => /^(\d{1,5})(\/\d{2,2})$/, []);
  const nroBoletaPattern = useMemo(() => /^(\d{1,5})(\/\d{2,2})(\/\d{2,2})$/, []);
  const nroCuitPattern = useMemo(() => /^\d{2,2}-\d{8,8}-\d{1,1}$/, []);

  const search = useCallback(
    async (query) => {
      let querySnap;
      let results = [];
      if (query?.length > 2) {
        setError(null);
        if (nroExpedientePattern.test(query)) {
          querySnap = await FirestoreService.getJuicioByNroExpediente(query);
        } else if (nroBoletaPattern.test(query)) {
          querySnap = await FirestoreService.getJuicioByNroBoleta(query);
        } else if (nroCuitPattern.test(query)) {
          querySnap = await FirestoreService.getJuiciosByCuit(query);
        } else {
          querySnap = await FirestoreService.getJuiciosByDemandado(query, false);
          if (querySnap.size === 0) {
            querySnap = await FirestoreService.getJuiciosByDemandado(query, true);
          }
        }
        results = FirestoreService.getDataFromQuerySnapshot(querySnap);
      } else {
        setError("Ingresar al menos 3 caracteres para buscar");
      }
      setJuicios(results);
    },
    [
      nroExpedientePattern,
      nroBoletaPattern,
      nroCuitPattern
    ]
  );

  const isFirstMount = useRef(true);
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      if (location.state?.query) {
        const q = location.state.query;
        window.history.replaceState({ query }, "deprecated-parameter");
        setQuery(q);
        search(q);
      }
    }
  }, [location?.state?.query, query, search]);

  const handleInputChange = (evt) => {
    if (nroExpedientePattern.test(evt.target.value)) {
      setQueryType("[# Expediente]");
    } else if (nroBoletaPattern.test(evt.target.value)) {
      setQueryType("[# Boleta]");
    } else if (nroCuitPattern.test(evt.target.value)) {
      setQueryType("[# CUIT]");
    } else if (evt.target.value.length > 2) {
      setQueryType("[Demandado]");
    } else {
      setQueryType("");
    }
    setQuery(evt.target.value);
  };

  const navigateTo = (page, state) => {
    navigate(page, {
      replace: true,
      state,
    });
  };

  return (
    <Styles>
      <div className="page-name">SearchPage</div>

      <div className="header">Buscar Juicios</div>

      <div className="main">
        <div className="span-row search">
          <span className="queryType">{queryType}</span>
          <input
            type="search"
            placeholder="nroBoleta? cuit? demandado?"
            autoComplete="off"
            name="query"
            onChange={handleInputChange}
            onKeyDown={(evt) => evt.key === "Enter" && search(evt.target.value)}
            value={query}
          ></input>
          <SearchIcon />
        </div>
        {error && <small>{error}</small>}

        {juicios.length > 0 && (
          <div className="search-results">
            <header>Resultados de la busqueda [{juicios.length}]</header>

            <div className="table">
              {/* HEADER */}
              <div className="theader">
                {/* <div>id</div> */}
                <span>N&deg;Boleta (N&deg;Expediente)</span>
                <span>Demandado</span>
                <span>Cuit</span>
              </div>

              {juicios.map((juicio) => (
                <div
                  key={juicio.id}
                  className="trow"
                  onClick={() => navigateTo(`/juicios/${juicio.id}`, { juicio: { ...juicio } })}
                >
                  {/* <div>id: ${juicio.id}</div> */}
                  <div className="nros">
                    {juicio.nroBoleta}
                    <span>({juicio.nroExpediente})</span>
                  </div>
                  <div>{juicio.demandado}</div>
                  <div>{juicio.cuit}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Styles>
  );
}

