import { useRef } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useAuth } from "./services/firestore";

import TopBar from "./components/TopBarLegacy";

import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import JuicioFormPage from "./pages/JuicioFormPage";
import JuicioPage from "./pages/JuicioPage";
import SearchPage from "./pages/SearchPage";

import ExportPage from "./pages/ExportPage";
import PrintCedula from "./pages/PrintCedula";

import "normalize.css";
import styled from "styled-components";
import PrintOfficio from "./pages/PrintOficio";
import ReportsPage from "./pages/ReportsPage";
import PrintCedulaModel2 from "./pages/PrintCedulaModel2";

const bgImage = `${process.env.PUBLIC_URL}/theme-a/images/unsplash-lawyer-${
  Math.floor(Math.random() * 5) + 1
}.jpg`;
const Styles = styled.div`
  .test-mode-header {
    background-color: lightgoldenrodyellow;
    text-align: center;
  }

  .landing-page {
    background-image: url(${bgImage});
    height: 100vh;
    background-position: center center;
    background-size: 100% auto;
  }

  .page-name {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: lightgoldenrodyellow;
    /* text-align: center; */
    z-index: 2;
  }
`;

export default function App() {
  const currentUser = useAuth();
  const searchInputRef = useRef(null)

  /* FIXME causes blink while loading user state if refreshing page
  solution: not force login here but redirect on from specific pages
  */
  const renderLoginIfUnath = (component) => {
    if (currentUser) {
      return component;
    } else {
      return <LoginPage />;
    }
  };

  // read https://create-react-app.dev/docs/adding-custom-environment-variables/
  const isDev = process.env.NODE_ENV !== "production";

  return (
    <Styles>
      {isDev && <div className="test-mode-header">MODO PRUEBAS</div>}

      <BrowserRouter>
        {!currentUser && (
          <>
            <div className="landing-page">
              <LoginPage />
            </div>
          </>
        )}

        {currentUser && (
          <>
            <TopBar ref={searchInputRef} />

            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<LoginPage />} />

              <Route path="/juicios/:idParam/edit" element={renderLoginIfUnath(<JuicioFormPage />)} />
              <Route path="/juicios/:idParam" element={renderLoginIfUnath(<JuicioPage searchInputRef={searchInputRef} />)} />
              <Route path="/juicios/new" element={renderLoginIfUnath(<JuicioFormPage />)} />
              <Route path="/search" element={renderLoginIfUnath(<SearchPage />)} />
              <Route path="/reports" element={renderLoginIfUnath(<ReportsPage />)} />

              <Route path="/export" element={renderLoginIfUnath(<ExportPage />)} />

              <Route path="/printCedula" element={renderLoginIfUnath(<PrintCedula />)} />
              <Route path="/printCedulaModel2" element={renderLoginIfUnath(<PrintCedulaModel2 />)} />
              <Route path="/printOficio" element={renderLoginIfUnath(<PrintOfficio />)} />
            </Routes>
          </>
        )}
      </BrowserRouter>
    </Styles>
  );
}
