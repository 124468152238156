/* eslint-disable react-hooks/exhaustive-deps */
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import styled from "styled-components";
import {
  // FaPlus as PlusIcon,
  FaSave as SaveIcon
} from "react-icons/fa";
// import useInputList from '../hooks/useInputList';

const Styles = styled.div`
  /* border: 1px dashed red; */

  .main {
    display: flex;
    flex-direction: column; /* default: row */
    flex: 0 1 auto;
    flex-wrap: wrap;
    align-items: flex-start; /* default: stretch */
  }
`;

export default function NovedadForm({ saveNovedad }) {
  const [novedad, setNovedad] = useState({
    tipoNovedad: "E",
    tipo: "INTIMADO",
    fecha: new Date(),
    comentario: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNovedad({ ...novedad, [name]: value });
  };
  const handleSaveNovedad = () => {
    if (novedad.tipoNovedad !== "E") {
      // tipo field only applies to tipoNovedad "E" (etapa)
      delete novedad.tipo;
    }
    // convert fecha to timestamp before saving
    const newRecord = { ...novedad, fecha: Timestamp.fromDate(novedad.fecha) };
    // console.log("Novedades::: SAVING Novedad", newRecord);
    saveNovedad(newRecord);
  };
  return (
    <Styles>
      <div className="header">
        <h4>Agregar Novedad</h4>
      </div>

      <div className="main">
        <select
          name="tipoNovedad"
          onChange={handleInputChange}
          value={novedad.tipoNovedad}
          placeholder="Etapa Gestion o Comentario?"
        >
          <option value="E">Etapa</option>
          <option value="G">Gestion</option>
          <option value="C">Comentario</option>
        </select>

        {novedad.tipoNovedad === "E" && (
          <select
            name="tipo"
            onChange={handleInputChange}
            value={novedad.tipo}
            placeholder="Nombre de la Etapa"
          >
            <option disabled>Nombre de la Etapa</option>
            <option>INTIMADO</option>
            <option>EXCEPTCION</option>
            <option>SENTENCIA</option>
            <option>SENTENCIA NOTIFICADA</option>
            <option>BANCO</option>
            <option>TRANSFERENCIA</option>
            <option>INHIBICION</option>
            <option>ARCHIVO</option>
            <option>DESISTIMIENTO</option>
            <option>PAGO</option>
          </select>

          // <input type="text" name="tipo" onChange={handleInputChange} value={novedad.tipo} />
        )}

        <div style={{ display: "inline-flex" }}>
          <ReactDatePicker
            locale="es"
            dateFormat="dd/MM/yy"
            placeholderText="Fecha"
            isClearable
            selected={novedad.fecha}
            onChange={(date) => {
              if (date && date instanceof Date) {
                setNovedad({ ...novedad, fecha: date });
              }
            }}
          />
        </div>
        <div className="comentario">
          <div>Comentario: </div>
          <input
            className="span"
            type="text"
            name="comentario"
            onChange={handleInputChange}
            value={novedad.comentario}
            maxLength={200}
          />
          <small>{`Quedan ${200-novedad.comentario.length} caracteres`}</small>
        </div>
        {/* <PlusIcon /> */}
      </div>
      <div className="footer">
        <button onClick={handleSaveNovedad}>
          Guardar <SaveIcon />
        </button>
      </div>
    </Styles>
  );
}
