import { useLocation } from "react-router-dom";
import styled from "styled-components";

const PrintableWrapper = styled.div`
  --legal-paper-width: 22cm;
  --legal-paper-height: 36cm;
  --paper-margin-top: 20mm;
  --paper-margin-right: 15mm;
  --paper-margin-bottom: 20mm;
  --paper-margin-left: 20mm;
  --paper-content-width: calc(
    var(--legal-paper-width) - var(--paper-margin-left) - var(--paper-margin-right)
  );

  @media print {
    position: fixed;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;

    [contenteditable="true"] {
      background-color: white;
    }
    .dotted-underline {
      border-bottom: none !important;
      /* margin: 0 2px; */
    }
  }

  box-sizing: border-box;
  font-size: 12px;

  width: var(--legal-paper-width);
  height: var(---legal-paper-height);
  padding: 1cm;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;

  /* ONLY FOR TESTING */
  /* border: 1px dashed red; */
  background-color: #fafafa;

  [contenteditable="true"] {
    background-color: lightgoldenrodyellow;
  }

  .header {
    display: flex;
    flex-direction: column;

    .section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: end;
    }
  }
  .section {
    width: var(--paper-content-width);
    margin-top: 0.7cm;
    .salutation {
      margin-bottom: 0.7cm;
    }
    & > * {
      /* margin: 5px 0; */
      line-height: 1.2rem;
    }
  }

  .title,
  .subtitle {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1.1rem;
  }
  .title {
    font-size: 36px;
  }
  .subtitle {
    font-size: 24px;
  }
  .to {
    text-transform: uppercase;
    div {
      max-width: calc(var(--legal-paper-width) / 4);
    }
  }
  .signature {
    display: flex;
    justify-content: flex-end;
  }

  /* .rows {
    line-height: 1.1rem;
  } */
  .tab {
    margin-left: 80px;
  }

  .break {
    flex-basis: 100%;
    height: 0;
  }
  .dotted-underline {
    border-bottom: 1px dotted;
    margin: 0 2px;
  }
  .CAPS {
    text-transform: uppercase;
  }
  .bold {
    font-weight: 600;
  }
`;

export default function PrintOfficio({data}) {
  const location = useLocation();
  data = location.state?.data;
  data = data || {
    nroBoleta: "1234/12/12",
    nroExpediente: "E001234/12/12",
    demandado: "AUTOMOVILES Y SERVICIOS S.R.L.",
    domicilio: "SUIPACHA 211 PISO 11 OF. C",
    localidad: "C.A.B.A.",
    fuero: "SS",
    juzgado: "4",
    secretaria: "2",
  };

  const now = new Date();
  const month = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
  const today = {
    date: now.getDate(),
    month: month[now.getMonth()],
    year: now.getFullYear()
  }

  const emptyDottedSpace = (
    <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
      &nbsp;&nbsp;&nbsp;&nbsp;
    </span>
  );

  return (
    <PrintableWrapper>
      <div className="header">
        <div>
          <div>DR ATUCHA</div>
          <div>BD. N&deg; {data.nroBoleta}</div>
        </div>
        <div className="section">
          <span>
            <img className="logo" width="170px" src="images/logo-afip.png" alt="logo AFIP" />
          </span>
          <div>
            <div contentEditable="true" suppressContentEditableWarning={true}>
              2020 - Año del General Manuel Belgrano
            </div>
            <div contentEditable="true" suppressContentEditableWarning={true}>
              Buenos Aires, {today.date} de {today.month} de {today.year}
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="title">Oficio</div>
        <div className="subtitle">Inhibicion general de bienes</div>
      </div>

      <div className="section to">
        <div>Buenos Aires,</div>
        <div>Se&ntilde;or Director</div>
        <div>del registro de la propiedad inmueble</div>
        <div>de la Capital Federal</div>
        <div>S________________/________________D</div>
      </div>

      <div className="section">
        <div className="salutation">De mi consideraci&oacute;n:</div>
        <div>
          <span className="tab" />Me dirijo a Ud. en los autos caratulados <strong>"FISCO NACIONAL (AFIP - DGI) c/ {data.demandado} s/ Ejecuci&oacute;n Fiscal"</strong> s/ Ejecución Fiscal", Expte N&deg; {data.nroExpediente}, Boleta de Deuda N&deg; {data.nroBoleta}, -Agente Fiscal Dr. Atucha-, que tramitan por ante el Juzgado {data.juzgado} a cargo del/la Dr./Dra. <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>COMPLETAR</span>, Secretaria N&deg; {data.secretaria} a cargo del/la Dr./Dra. <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>COMPLETAR</span>, sito en la calle <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>COMPLETAR</span> de la Ciudad Aut&oacute;noma de Buenos Aires, a fin de que se sirva disponer lo necesario para <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>INSCRIBIR o LEVANTAR</span> la <span className="CAPS">inhibicion general de bienes</span> a la parte demandada, cuyos data referenciales se indican a continuaci&oacute;n:
        </div>
      </div>

      <div className="section">
        <div className="rows">
          <div>
            <span className="bold CAPS">nombre / raz&oacute;n social: </span>
            <span>{data.demandado}</span>
          </div>
          <div>
            <span className="bold">C.U.I.T. / C.U.I.L. / C.D.I.: </span>
            <span>{data.cuit}</span>
          </div>
          <div>
            <span className="bold">Inscripci&oacute;n ante la Inspecci&oacute; General de Justicia: </span>
            <span>{data.cuit}</span>
          </div>
          <div>
            <span className="bold">N&deg; Inscripci&oacute;n: </span> {emptyDottedSpace} <span className="bold">Libro: </span>
            {emptyDottedSpace}
          </div>
          <div>
            <span className="bold">Domicilio Legal: </span>
            <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
              {data.domicilio}
            </span>
          </div>
          <div>
            <span className="bold">Domicilio Fiscal: </span>
            <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
              {data.domicilio}
            </span>
          </div>
          <div>
            <span className="bold">D.N.I. / CEDULA / PASAPORTE: </span>
            {emptyDottedSpace}
          </div>
          <div>
            <span className="bold">Nacionalidad: </span>
            {emptyDottedSpace}
          </div>
          <div>
            <span className="bold">Nombre del Padre: </span>
            {emptyDottedSpace}
          </div>
          <div>
            <span className="bold">Apellido y Nombre de la Madre: </span>
            {emptyDottedSpace}
          </div>
        </div>
      </div>

      <div className="section">
        <span className="tab" />
        El presente oficio fue decretado por el juez de la causa mediante auto de fecha{" "}
        <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
          DIA
        </span>{" "}
        de{" "}
        <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
          MES
        </span>{" "}
        de{" "}
        <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
          A&Ntilde;O
        </span>{" "}
        cuya parte pertinente dice:{" "}
        <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
          COMPLETAR
        </span>
        . Fdo.:{" "}
        <span className="dotted-underline" contentEditable="true" suppressContentEditableWarning={true}>
          COMPLETAR
        </span>
        , Juez Federal."
      </div>

      <div className="section">
        <div>
          <span className="tab" />
          El resultado de la medida cautelar cuya anotaci&oacute; se solicita, deber&aacute; ser informado al
          Juzgado, adjuntando las constancias documentales que fueran pertinentes.
        </div>
        <div>
          El art&iacute;culo 3&deg; del Decreto 1108/98 dice en su parte pertinente: "La exedici&oacute;n de
          las informaciones solicitadas y, en su caso, la inscripci&oacute;n de embargos u otras medidas
          cautelares sobre bienes inmuebles, muebles o derechos patrimoniales registrables, o sobre la persona
          de sus titulares, no podr&aacute; demorarse, restringirse, limitarse o suspenderse por la
          aplicaci&oacute;n de normas de car&aacute;cter administrativo, ni supeditarse al pago previo de
          aranceles, tasas y/o aportes de cualquier naturaleza. Sin perjuicio de ello, los organismos
          detallados en la planilla anexa al art. 1°, podrón celebrar con la ADMINISTRACION FEDERAL DE
          INGRESOS PUBLICOS, convenios que posibiliten compatibilizar esta disposición con las leyes o
          reglamentaciones que establezcan dichos aranceles, tasas o aportes. Cuando las solicitudes de
          informes y de inscripción de medidas cautelares se emitan en juicios de ejecución fiscal, los
          importes de los créditos por los conceptos aludidos deberán ser comunicados al juzgado requirente, a
          los fines de su inclusión en la respectiva liquidación de costas a cargo del deudor."
        </div>
      </div>

      <div className="section">
        <span className="tab" /> Se deja constancia que se encuentran autorizados para diligenciar el presente
        oficio y firmar minutas el Dr. Carlos Jorge Atucha.-
      </div>

      <div className="section signature">
        <span>Saludos a Ud. atentamente</span>
      </div>
    </PrintableWrapper>
  );
}
