import { useState, useEffect, useRef, forwardRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAuth, logOut } from "../services/firestore";
// import * as FirestoreService from "../services/firestore";

import {
  FaSignInAlt as SignInIcon,
  FaSignOutAlt as SignOutIcon,
} from "react-icons/fa";
import { FcSearch as SearchIcon } from "react-icons/fc";
// import { FcComments as NotificationIcon} from "react-icons/fc"
// import { BiMessage as MessagesIcon } from "react-icons/bi";

import styled from "styled-components";

export const TopBarStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 45px; */

  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
  color: #555;

  //background: linear-gradient(45deg, #0288d1 0%, #039be5 70%, #26c6da 100%);
  background: #039be5;

  .left {
    .logo-link {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; /* row -> horiz */
      align-items: center; /* row -> vertical */
    }
    .logo {
      margin-left: 3px;
      height: 30px;
      background-color: white;
    }
  }
  .center {
    flex-grow: 1;
    margin: 0 20px;

    .search {
      min-width: 220px;
      margin: 5px;
      padding: 3px;
      border-radius: 7px;
      background: rgba(255, 255, 255, 0.5);
      transition: 0.7s ease all;
      &:focus-within {
        background: white;
      }

      .search-icon {
        margin-right: 5px;
        vertical-align: middle;
      }
      input {
        width: 85%;
        padding: 5px;
        outline: none;
        border: none;
        background: transparent;

        font-size: 0.8rem;

        &:focus {
          color: #333;
          background: white;
          border-bottom: 1px dotted #9e9e9e;
        }
      }
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* row -> horiz */
    align-items: center; /* row -> vertical */

    * {
      margin: 0 3px;
    }
    a {
      padding: 10px 5px;
      text-decoration: none;
      color: white;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .avatar {
      width: 32px;
      border-radius: 100px;
    }
  }
`;

const TopBar = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(true);
  const currentUser = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchInputRef = ref;

  useEffect(() => {
    setLoading(false);

    const isAgregarJuicioPage = /^\/juicios\/new/.test(location.pathname);

    if(!isAgregarJuicioPage) {
      searchInputRef?.current?.focus();
    }
  }, [location, searchInputRef])

  async function handleLogOut() {
    setLoading(true);
    try {
      logOut();
    } catch (e) {
      console.log("err", e);
    }
    setLoading(false);
  }

  const navigateTo = (page, state) => {
    navigate(page, {
      replace: true,
      state
    });
  }



  const isSearchPageActive = window.location.pathname === "/search";
  return (
    <TopBarStyles>
      <div className="left">
        <NavLink to="/" end className="logo-link">
          <img src="/images/logo-agente-fiscal-2s.png" alt="logo" className="logo" />
        </NavLink>
      </div>
      <div className="center">
        {loading && <span>Cargando...</span>}
        {!isSearchPageActive && (
          <div className="search">
            <SearchIcon className="search-icon" />
            <input
              ref={searchInputRef}
              placeholder="N&deg;Boleta? CUIT? Demandado?"
              onKeyDown={
                (evt) =>
                  evt.key === "Enter" &&
                  navigateTo("/search", {
                    query: evt.target.value,
                    seeUnique: true,
                  }) //delegateSearch(evt.target.value)
              }
            />
          </div>
        )}
      </div>

      <div className="right">
        <NavLink to="juicios/new">Agregar</NavLink>
        <NavLink to="reports">Reportes</NavLink>

        {!currentUser && (
          <NavLink to="login">
            <SignInIcon />
          </NavLink>
        )}
        {currentUser && (
          <span className="user-wrapper">
            <Dropdown
              toggler={
                <img src="/images/avatar-lego-0.jpg" alt="avatar" className="avatar" />
                // <img src="https://randomuser.me/api/portraits/lego/0.jpg" alt="avatar" />
              }
            >
              {currentUser.email === "jorge.atucha@gmail.com" && (
                <DropdownItem onClick={() => navigateTo("/export")}>Export</DropdownItem>
              )}
              <DropdownItem leftIcon={<SignOutIcon />} onClick={handleLogOut}>
                Salir
              </DropdownItem>
            </Dropdown>
          </span>
        )}
      </div>
    </TopBarStyles>
  );
})

const DropdownStyles = styled.div`
  .dropdown-toggler:hover {
    cursor: pointer;
    transition: filter 250ms;
    filter: drop-shadow(0 0 5px grey);
  }
  .dropdown-menu {
    position: absolute;
    right: 0;
    width: 100px;
    overflow: hidden;
    margin: 1px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    background-color: whitesmoke;
    border-radius: 5px;
  }
  .dropdown-item {
    height: 30px;
    padding: 3px;
    display: flex;
    align-items: center;
    gap: space-between;

    .icon-left,
    .icon-right {
      width: 20px;
      cursor: default;
    }
    .label {
      cursor: default;
    }
    &:hover {
      background-color: #eee;
    }
  }
`;
function Dropdown(props) {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setOpen(false));

  function DropdownMenu(props) {
    return <div className="dropdown-menu">{props.children}</div>;
  }

  return (
    <DropdownStyles ref={wrapperRef}>
      <span className="dropdown-toggler" onClick={() => setOpen(!open)}>
        {props.toggler}
      </span>
      {open && <DropdownMenu>{props.children}</DropdownMenu>}
    </DropdownStyles>
  );
}
function DropdownItem(props) {
  return (
    <span className="dropdown-item" onClick={props.onClick}>
      <span className="icon-left">{props.leftIcon} </span>
      <span className="label">{props.children}</span>
      <span className="icon-right">{props.leftRight} </span>
    </span>
  );
}
function useClickOutside(ref, handler) {
  useEffect(() => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });
}

export default TopBar;