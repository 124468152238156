import { useLocation } from "react-router-dom";
import styled from "styled-components";

const PrintableWrapper = styled.div`
  --tab-size: 100px;

  @media print {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }

  * {
    box-sizing: border-box;
    font-size: 16px;
  }
  [contenteditable="true"] {
    background-color: lightgoldenrodyellow;
  }
  .text-right {
    text-align: right;
  }
  .tab {
    margin-left: var(--tab-size);
  }
  .tab-right {
    margin-right: var(--tab-size);
  }

  body {
    /* margin: 20mm 15mm 20mm 15mm; */
    margin-left: auto;
    margin-right: auto;

    font-family: Courier, sans-serif, monospace;
    line-height: 1.5rem;
  }

  /* ONLY FOR TESTING *
  border: 1px dashed red;
  background-color: #eee;*/

  .page {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .section.header {
      display: flex;
      flex-direction: column;
      font-weight: 500;

      .title {
        align-self: center;
        font-size: 1.2em;
        margin: 10px 0;
      }
      .subtitle {
        align-self: center;
        font-size: 1.1em;
      }
    }

    .section.defendant {
      font-weight: 500;
      margin: 20px 0;
    }
    .section.table {
      font-weight: 500;
      margin: 0 5px 20px 0;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-around;
      align-items: center;

      height: 54px;
      border-top: 1px solid black;
      border-bottom: 1px solid black;

      & > span {
        height: 25px;
        display: flex;
        align-items: center;
      }
    }
    .section.resolution {
    }
    .footer {
    }
  }
`;

export default function PrintCedulaModel2({data}) {
  const location = useLocation();

  data = location.state?.data;
  if (!data) {
    alert("Juicio no seleccionado - Usando datos de prueba");
    data = data || {
      nroBoleta: "01234/12/12",
      nroExpediente: "01234/12",
      demandado: "NOMBRE_DEL_DEMANDADO",
      domicilio: "DOMICILIO_DEL_DEMANDADO",
      localidad: "C.A.B.A.",
      fuero: "SS",
      juzgado: "4",
      secretaria: "2",
    };
  }

  const now = new Date();
  const month = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
  const today = {
    date: now.getDate(),
    month: month[now.getMonth()],
    year: now.getFullYear()
  }

  return (
    <PrintableWrapper>
      <div className="page">
        <div className="header">
          <div className="identifier">BD {data.nroBoleta}</div>
        </div>
        <div className="section header">
          <div className="title">A.F.I.P. - D.G.I.</div>
          <div className="subtitle">CEDULA DE NOTIFICACION</div>
        </div>
        <div className="section defendant">
          <div>SEÑOR/ES: {data.demandado}</div>
          <div>DOMICILIO: {data.domicilio}</div>
          <div>{data.localidad}</div>
          <div>CARACTER: CONSTITUIDO</div>
        </div>
        <div className="section table">
          <span>N&deg; ORDEN</span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            -
          </span>
          <span>EXPTE.</span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {data.nroExpediente}
          </span>
          <span>FUERO</span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {data.fuero}
          </span>
          <span>Juzgado</span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {data.juzgado}
          </span>
          <span>Secretaria</span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {data.secretaria}
          </span>
          <span>Copias:</span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            No
          </span>
        </div>

        <div className="section resolution">
          <span className="tab">Hago saber a Ud. que en el expediente caratulado “AFIP-DGI c/ </span>
          <span>{data.demandado} </span>
          <span>s/EJECUCION FISCAL” que tramita con intervención del </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            DATOS_DEL_JUZGADO a cargo del Dr. NOMBRE_DEL_JUEZ
          </span>
          <span>- Secretaría N° </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            XX
          </span>
          <span> - sito en </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            DIRECCION_DEL_JUZGADO
          </span>
          <span> de la Capital Federal, </span>
          <span>se le notifica la siguiente resolución: </span>
          <br/>
          <span>“Buenos Aires, </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>DIA</span>
          <span> de </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>MES</span>
          <span> de </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>AÑO</span>
          <span>...VISTO Y CONSIDERANDO:...RESUELVO: </span>
          <span>I.- Declarar expedita la ejecución de la deuda reclamada contra </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            NOMBRE_DEL_DEMANDADO
          </span>
          <span>
            , quedando la accionante habilitada para llevarla adelante hasta obtener el íntegro pago del
            capital de ${" "}
          </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            XXXXX.XX
          </span>
          <span>
            , con más sus intereses. II.- Imponer las costas del proceso a la ejecutada perdidosa. III. -
            Regístrese y notifíquese administrativamente. Asimismo, notifíquese al Sr. Representante del
            Ministerio Público en su despacho mediante comunicación electrónica. Oportunamente archívese.{" "}
          </span>
          <span contentEditable="true" suppressContentEditableWarning={true}>
            NOMBRE_DEL_JUEZ
          </span>
          <span>. JUEZ FEDERAL."</span>
        </div>

        <div className="section">
          La presente notificaci&oacute;n se practica de conformidad con lo establecido en los arts. 92 y 100
          (inciso e) de la Ley N&deg; 11683 (t.o. en 1998 y sus modificaciones), art. 200 Ley 11683 (texto
          seg&uacute;n Ley 27430/17)
        </div>

        <div className="section">QUEDA USTED DEBIDAMENTE NOTIFICADO</div>

        <div className="section">
          Buenos Aires,{" "}
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {today.date}
          </span>{" "}
          de{" "}
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {today.month}
          </span>{" "}
          de{" "}
          <span contentEditable="true" suppressContentEditableWarning={true}>
            {" "}
            {today.year}.
          </span>
        </div>

        <div className="section text-right tab-right">
          <div className="row">Dr. Carlos Jorge Atucha</div>
          <div className="row">T&deg;36 - F&deg;785</div>
          <div className="row">COLEGIO DE ABOGADOS</div>
          <div className="row">CAPITAL FEDERAL</div>
        </div>

        <div className="footer">
          <div>AIP-Agencia Nro. 49</div>
          <div>Tte. Gral. Juan D. Per&oacute;n 1378</div>
          <div>Capital Federal</div>
        </div>
      </div>
    </PrintableWrapper>
  );
}
