import {useRef, useEffect, useState} from 'react';
import styled from "styled-components";

import { getDateString } from "../../utils/utils";

import './css-progress-wizard.css';

const EtapasTimelineStyle = styled.div`
  display: flex;
  align-items: center;
  .hide {
    display: none;
  }
  .progress-indicator {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin: 0 !important;
    padding: 5px 10px 5px 10px !important;
    background-color: #d9edf7aa;
    /* height: 100px; */

    li {
      min-width: 100px;
      margin-top: 24px !important;
      padding-right: 3px !important;

      time {
        position: relative;
        top: -45px;
      }

      .stacked-text {
        position: relative;
        top: -20px;
        transform: rotate(-20deg);
        display: inherit;
      }
    }
  }
`;
export default function EtapasTimeline({ etapas }) {
  const [overflowActive, setOverflowActive] = useState(false);
  const ulRef = useRef();

  useEffect(() => {
    goRight();
    if (checkOverflow(ulRef)) {
      setOverflowActive(true);
    }
  }, []);

  const checkOverflow = element => {
    return element?.current?.offsetWidth < element?.current?.scrollWidth;
  };

  const goLeft = (step) => {
    if (step) {
      ulRef.current.scrollLeft -= step;
    } else {
      ulRef.current.scrollLeft = 0;
    }

  }
  const goRight = (step) => {
    if (step) {
      ulRef.current.scrollLeft += step;
    } else {
      ulRef.current.scrollLeft = ulRef.current.scrollWidth;
    }
  };

  return (
    <EtapasTimelineStyle>
      <span className={overflowActive ? "" : "hide"}
      onClick={(evt) => goLeft()}>&lt;</span>

      <ul ref={ulRef} className="progress-indicator" onClick={goRight}>
        {etapas.map((etapa, idx) => (
          <li className="active" key={`etapa-${idx}`}>
            <span className="bubble" />
            <time>{getDateString(etapa.fecha)}</time>
            <span className="stacked-text">{etapa.tipo}</span>
            {etapa.comentario?.length > 0 && <>{etapa.comentario}</>}
          </li>
        ))}
      </ul>
      <span className={overflowActive ? '':'hide'}
      onClick={(evt) => goRight()}>&gt;</span>
    </EtapasTimelineStyle>
  );
}
